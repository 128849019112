.user-profile {
    background-clip: padding-box;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 5px 10px rgba(33, 33, 33, .2);

    .user-profile-name {
        font-size: 16px;
        display: flex;
        align-items: center;
        font-weight: 600;
        color: #213666;
    }

    .user-profile-avatar {
        background-color: rgb(3, 201, 215);
        border: 3px solid #fff;
        left: 50%;
        position: absolute;
        top: 12px;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}