.product-page .section {
    margin: 8px 0;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -.2px;
    color: #2C2C2C;
    padding: 24px 16px 16px;
    font-weight: 700;
    background-color: rgba(3, 201, 215, 0.7);
}

.product-page .content {
    padding: 10px;
    overflow: auto;
    max-height: calc(83vh);
}

.filter-container {
    position: relative;
    padding: 10px 16px 10px 10px
}

.filter-button {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    gap: 8px
}

.filter-button {
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.65);
}

.filter-button:hover {
    border-color: #1890ff;
    color: #1890ff;
}

.active-filter-button {
    border-color: #1890ff;
    color: #1890ff;
}

.active-filter-button:hover {
    border-color: #1890ff;
    color: #40a9ff;
}

.filter-icon {
    transition: color 0.3s;
    /* Add transition for color change */
}

.filter-content {
    display: none;
    transition: height 0.3s ease;
    /* CSS transition for animation */
    box-shadow: 0px 2px 5px rgba(131, 131, 131, 0.4);
    border-radius: 8px;
}

.filter-footer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    padding: 0 10px 10px 10px;
}

.filter-content.expanded {
    border-radius: 8px;
    box-shadow: 0 2px 5px #83838366;
    display: block;
    margin-top: 16px;
    position: absolute;
    z-index: 100;
    right: 0;
    width: 100%;
    top: 35px;
    background: white;
}

/* Additional styling for filter content */
.filter-content input,
.filter-content select {
    margin-bottom: 10px;
    width: 100%;
    padding: 8px;
}

.filter-content select {
    /* Add your select styles here */
}

.filter-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    /* Adjust as needed */
    padding: 16px;
}

.filter-grid__item .ant-select {
    /* display: contents; */
    width: 100%;
    min-height: 40px;
}

.filter-grid__item:last-child,
.filter-grid__item:nth-child(1) {
    grid-column: span 2;
}

.filter-text-highlight {
    font-weight: 500;
    color: blue
}

.filter-button button {
    height: 38px;
}