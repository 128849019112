.card-wrapper {
    position: relative;
    border-radius: 12px;
    height: 460px;
}

.card .title {
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    letter-spacing: -.2px;
    color: #2C2C2C;
}

.card-wrapper .product-created-by {
    font-weight: 500;
    font-size: 20px;
}

.card-wrapper .img-wrapper {
    height: 160px;
}

.card-wrapper .img-wrapper .image {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.card-wrapper .description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.card-wrapper .footer {
    position: absolute;
    bottom: 12px;
}

.card-wrapper .status {
    top: 31%;
    left: -10px;
    padding: 4px;
    position: absolute;
    min-height: 20px;
    background-color: #2979ff;
    color: white;
    width: 60px;
    text-align: center;
    justify-content: center;
    border-radius: 8px;
}

.card-wrapper .sold {
    top: 31%;
    left: -10px;
    padding: 4px;
    position: absolute;
    min-height: 20px;
    background-color: #6c757d;
    color: white;
    min-width: 40px;
    text-align: center;
    justify-content: center;
    border-radius: 8px;
}
.card-wrapper .stop_sale {
    top: 31%;
    left: -10px;
    padding: 4px;
    position: absolute;
    min-height: 20px;
    background-color: black;
    color: white;
    min-width: 40px;
    text-align: center;
    justify-content: center;
    border-radius: 8px;
}

.card-wrapper .sale_urgent {
    top: 31%;
    left: -10px;
    padding: 4px;
    position: absolute;
    min-height: 20px;
    background-color: #b91a1a;
    color: white;
    min-width: 40px;
    text-align: center;
    justify-content: center;
    border-radius: 8px;
}

.card-wrapper .createdat {
    font-weight: 500;
    min-height: 20px;
    background-color: #e0e0e0;
    color: black;
    min-width: 40px;
    text-align: center;
    justify-content: center;
    border-radius: 8px;
    padding: 4px 6px;
}

.card-wrapper .product-name {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
}

.card-wrapper .icon {
    max-width: 32px;
}

.card-wrapper .product-location {
    font-size: 14px;
    line-height: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
}

.card-wrapper .icon-save-product {
    border: 0;
    background-color: #fff;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    font-size: 1.25rem;
    transition: .25s ease;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 3px 8px 0 rgba(0, 0, 0, .15);
    z-index: 1;
    cursor: pointer;
    color: #565656;

    svg {
        width: 20px;
        height: 20px;
    }

    &:hover,
    &:focus {
        background-color: #EC4646;
        color: #FFF;
    }

    &.is-saved {
        background-color: #EC4646;
        color: #FFF;
    }
}