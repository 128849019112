.product-detail {}

.product-detail .img-wrapper {
    /* height: 35vh; */
}

.product-detail .price {
    font-weight: 500;
    font-size: 20px;
    color: orange;
}

.product-detail .content {
    padding: 24px;
}

.product-detail .title_sale {
    background: #830051;
    border-radius: 10px 10px 0px 0px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    margin: 0;
}

.product-detail .title_sale_urgent {
    background: #b91a1a;
    border-radius: 10px 10px 0px 0px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    margin: 0;
}

.product-detail .title_stop_sale {
    background: black;
    border-radius: 10px 10px 0px 0px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    margin: 0;
}
.product-detail .title_sold {
    background: #6c757d;
    border-radius: 10px 10px 0px 0px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    margin: 0;
}

.product-detail .section {
    margin: 8px 0;
}

.product-detail .sale-date {
    font-size: 14px;
}

.product-detail .location {
    font-weight: 500;
    font-size: 16px;
    /* color: gray; */
    display: flex;
}

.product-detail .post-news {
    background: #2962ff;
    border-radius: 8px;
    border: 1px solid #2962ff;
    color: #fff;
    padding: 4px 8px;
}

.product-detail .carousel-container {
    width: 100%;
    height: 400px;
    background-color: #5DAEBF;
    position: relative;
}

.product-detail .slick-slider .slick-prev::after {
    width: 25px !important;
    height: 25px !important;
    left: 20px;
}

.product-detail .slick-slider .slick-next::after {
    width: 25px !important;
    height: 25px !important;
    left: -20px;
}

.ant-image-preview-switch-left,
.ant-image-preview-switch-right {
    width: 60px;
    height: 60px;
    top: -30px;
}

.ant-image-preview-switch-left svg {
    width: 40px;
    height: 40px;
}

.ant-image-preview-switch-right svg {
    width: 40px;
    height: 40px;
}


.image-wrapper {
    /* width: 100%;
    height: 400px; 
    display: flex;
    justify-content: center;
    align-items: center; */
    width: 100%;
    height: 400px;
    position: relative;
}

.image-wrapper .ant-image {
    position: static !important;
    /* Reset position */
    display: inline !important;
}

.image-wrapper img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
}


@media (min-width: 400px) {
    .col-md-4 {
        flex: 0 0 auto;
        width: 33.33333333% !important;
    }
}

.ant-carousel {
    .slick-next {
        &::before {
            content: '';
        }
    }

    .slick-prev {
        &::before {
            content: '';
        }
    }
}

.product-detail .icon-save-product {
    position: absolute;
    right: 10px;
    width: 24px;
    height: 24px;
}

.contact-employee-product {}