.avatar {
    font-size: 14px;
    color: #212121;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: #f4f6f8;
    color: #fff;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 50%;
    font-weight: 700;
}

.avatar-string {
    position: absolute;
    left: 50%;
    -webkit-transform-origin: 0 center;
    -ms-transform-origin: 0 center;
    transform-origin: 0 center;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}