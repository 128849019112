/* .main-wrapper  {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 64px);
    overflow-y: hidden;

    &__search-bar{
        height: 56px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 12px 24px 12px 0;
        gap: 8px;
        position: relative;
        box-shadow: 0px 4px 8px rgb(0 0 0 / 6%), 0px 0px 4px rgb(0 0 0 / 6%), 0px 0px 1px rgb(0 0 0 / 4%);
    }

    &__content {
        background: #F7F8F9;
        padding-bottom: 10vh;
        padding-left: 24px;
        padding-right: 24px;
        display: flex;
        flex: 1;
        flex-direction: column;
        height: 100%;
        // min-height: 80vh;

        &__header {
            display: flex;
            align-items: flex-start;
            padding: 10px;
            margin: 16px;
            // flex: 1;
        }

        &__main{ 
            display: flex;
            // height: 100%;
            overflow-y: hidden;
        }
    }
}

.permission-status {
    width: 8px;
    height: 8px;
    border-radius: calc(8px / 2); 
    margin-right: 10px;
    background: #FC625D;
    &_active{
        background: #30C894;
    }
}

.hrv-drawer-body {
    padding: 0
}

.hrv-drawer-open .drawer-body {
    padding-bottom: calc(61px + 10px);
}

.bpm-filter {
    &_field {
        .hrw-treecompany-box-wrapper {
            width: 100%;
            cursor: text;
        }
    }
} */

.main-wrapper__search-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;
    padding-right: 16px;

    /* height: 56px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 12px 24px 12px 0;
    gap: 8px;
    position: relative;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 6%), 0px 0px 4px rgb(0 0 0 / 6%), 0px 0px 1px rgb(0 0 0 / 4%); */

}

.main-wrapper__search-bar .btn-default {
    border-radius: 4px;
    background-color: #1677ff;
    padding: 0 8px;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 8px;
    color: white;
    cursor: pointer;
}

.main-wrapper__search-bar .btn-default:hover {
    background-color: #4096ff;
    color: #fff
}

.media-file-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.btn-clear-all-image {
    cursor: pointer;
    color: blue
}

.product-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}