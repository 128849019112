.leaflet-container {
    height: 85vh;
    width: 60vw;
    max-width: -webkit-fill-available;
}

.leaflet-div-icon {
    background: transparent;
    border: none;
}

.tooltip {
    width: 190px;
    background: #ffffff;
    border-radius: 10px;
    filter: drop-shadow(0px 0px 15px rgba(129, 129, 129, 0.25));
}

.title {
    background: #830051;
    border-radius: 10px 10px 0px 0px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    margin: 0;
}

.amount {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 10px;
    font-size: 14px;
    line-height: 20px;
}

/* .leaflet-container {
    margin: 0 auto;
}

.leaflet-control,
.leaflet-popup-close-button {
    display: none;
}

.leaflet-zoom-animated {
    pointer-events: visible;
}

.leaflet-map-pane {
    position: relative;
}

.leaflet-popup-pane {
    position: absolute;
    top: 0;
}

.leaflet-proxy.leaflet-zoom-animated {
    display: none;
}

.leaflet-pane.leaflet-tooltip-pane {
    position: absolute;
    top: 50px;
    z-index: 1;
}

.leaflet-popup-content {
    font-size: 12px;
} */