@-webkit-keyframes button-loading-spinner {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes button-loading-spinner {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.btn {
    font-size: 14px;
    line-height: 16px;
    /* min-width: 100px; */
    padding: 9px 12px;
    border-radius: 4px;
    font-weight: bold;
    vertical-align: baseline;
    cursor: pointer;
    height: 40px;
}

.btn.OG {
    min-width: 100px;
    height: unset;
}

.btn:focus {
    outline: none;
}

.btn.btn-primary {
    background: #2979ff;
    border: 1px solid #2979ff;
    box-shadow: none;
    color: #fff;
}

.btn.btn-primary:hover {
    background: #026db3;
    border: 1px solid #026db3;
}

.btn.btn-primary[disabled] {
    background: #b3d7ee;
    border: 1px solid #b3d7ee;
}

.btn.btn-secondary {
    background: #5cb85c;
    border: 1px solid #5cb85c;
    box-shadow: none;
    color: #fff;
}

.btn.btn-secondary:hover {
    background: #53a653;
    border: 1px solid #53a653;
}

.btn.btn-secondary[disabled] {
    background: #ceeace;
    border: 1px solid #ceeace;
}

.btn.btn-warning {
    background: #f0ad4e;
    border: 1px solid #f0ad4e;
    box-shadow: none;
    color: #fff;
}

.btn.btn-warning:hover {
    background: #d89c46;
    border: 1px solid #d89c46;
    box-shadow: none;
    color: #fff;
}

.btn.btn-warning[disabled] {
    background: #fbe6ca;
    border: 1px solid #fbe6ca;
    box-shadow: none;
    color: #fff;
}

.btn.btn-danger {
    background: #d9534f;
    border: 1px solid #d9534f;
    box-shadow: none;
    color: #fff;
}

.btn.btn-danger:hover {
    background: #c34b47;
    border: 1px solid #c34b47;
}

.btn.btn-danger[disabled] {
    background: #f4cbca;
    border: 1px solid #f4cbca;
}

.btn.btn-default {
    background: #eeeeee;
    /* border: 1px solid #9CA7B2; */
    box-shadow: none;
    color: #021337;
}

.btn.btn-default.OG {
    background: #fff;
    border: 1px solid #9ca7b2;
    box-shadow: none;
    color: #474747;
}

.btn.btn-default:hover {
    background: #e0e0e0;
}

.btn.btn-default:hover.OG {
    background: #f4f6f8;
}

.btn.btn-default[disabled] {
    background: #fafafa;
    /* border-color: #E1E5E8; */
    color: #b0b0b0;
}

.btn.OG.btn-default[disabled] {
    background: #fff;
    border-color: #e1e5e8;
    color: #c8c8c8;
}

.btn.btn-default-danger {
    background: #fff;
    border: 1px solid #d9534f;
    box-shadow: none;
    color: #d9534f;
}

.btn.btn-default-danger:hover {
    background: #f4f6f8;
}

.btn.btn-default-danger[disabled] {
    background: #fff;
    border-color: #f4cbca;
    color: #f4cbca;
}

.btn.btn-link {
    border-color: transparent;
    color: #0279c7;
}

.btn.btn-link.btn-pd-none {
    padding: 0;
    min-width: auto;
    height: auto;
}

.btn.btn-link-danger {
    color: #d9534f;
}

.btn.btn-link:hover {
    color: #026db3;
}

.btn.btn-link-danger:hover {
    color: #c34b47;
}

.btn-loading {
    cursor: default;
    text-shadow: none;
    color: transparent;
    position: relative;
    -webkit-transition: border-color 0.2s ease-out;
    transition: border-color 0.2s ease-out;
}

.btn-loading,
.btn-loading:hover,
.btn-loading:focus,
.btn-loading:active {
    color: transparent !important;
}

.btn-loading:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: #fff;
    border-bottom-color: transparent;
    margin-top: -8px;
    margin-left: -8px;
    width: 16px;
    height: 16px;
    -webkit-animation: button-loading-spinner 0.7s linear infinite;
    animation: button-loading-spinner 1s linear infinite;
}

.btn-default.btn-loading {
    background: #fff;
}

.btn-default.btn-loading:before {
    border-color: #9ca7b2;
    border-bottom-color: transparent;
}

.btn-primary.btn-loading {
    background: #026db3;
}

.btn-2-default {
    background-color: #f4f5fa;
    color: #474747;
}
.btn-2 {
    -webkit-transition: all 0.15s ease-in-out;
    -o-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    border-radius: 4px;
    padding: 10px 20px;
    line-height: 18px;
    cursor: pointer;
    font-size: 15px !important;
}
.btn-2-default.active,
.btn-2-default:active,
.btn-2-default:hover {
    background-color: #bac8d3;
    color: #474747;
}
