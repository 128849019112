.card-mobile {
    position: relative;
    height: 220px;
    text-decoration: none;
    border-radius: 12px;
}

.card-mobile .product-name {
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: -.7px;
    color: #2C2C2C;
}

.card-mobile .product-acreage {
    color: #2C2C2C;
    font-size: 15px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.card-mobile .product-price {
    color: #2C2C2C;
    font-size: 15px;
    font-weight: 600;
}

.card-mobile .img-wrapper {
    width: 25vw;
    height: 150px;
    padding: 12px 0;
}

.card-mobile .content-wrapper {
    padding: 5px 15px 5px 10px;
}

.card-mobile .description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.card-mobile .footer {
    position: absolute;
    bottom: 12px;
    right: 18px;
}

.btn-primay-layout {
    background: rgb(93, 174, 191);
    color: white;
}

.card-mobile-pc {
    position: relative;
    height: 450px;
    text-decoration: none;
    border-radius: 12px;
}

.card-mobile-pc .img-wrapper {
    margin: 0 auto;
    height: 250px;
}

.card-mobile .icon-save-product {
    position: absolute;
    right: 10px;
    bottom: 10px;
}

.card-mobile .status {
    top: 5px;
    right: 10px;
    padding: 4px;
    position: absolute;
    min-height: 20px;
    background-color: green;
    color: white;
    min-width: 40px;
    text-align: center;
    justify-content: center;
    border-radius: 8px;
    z-index: 9;
}

.card-mobile .sold {
    top: 5px;
    right: 10px;
    padding: 4px;
    position: absolute;
    min-height: 20px;
    background-color: #6c757d;
    color: white;
    min-width: 40px;
    text-align: center;
    justify-content: center;
    border-radius: 8px;
    z-index: 9;
}

.card-mobile .stop_sale {
    top: 5px;
    right: 10px;
    padding: 4px;
    position: absolute;
    min-height: 20px;
    background-color: black;
    color: white;
    min-width: 40px;
    text-align: center;
    justify-content: center;
    border-radius: 8px;
    z-index: 9;
}

.card-mobile .sale_urgent {
    top: 5px;
    right: 10px;
    padding: 4px;
    position: absolute;
    min-height: 20px;
    background-color: #b91a1a;
    color: white;
    min-width: 40px;
    text-align: center;
    justify-content: center;
    border-radius: 8px;
    z-index: 9;
}

.card-mobile .createdat {
    top: 7px;
    right: 120px;
    padding: 0px 5px;
    font-weight: 500;
    position: absolute;
    min-height: 20px;
    background-color: #e0e0e0;
    color: black;
    min-width: 40px;
    text-align: center;
    justify-content: center;
    border-radius: 8px;
}

.card-mobile-pc .createdat {
    top: 240px;
    right: 20px !important;
    padding: 2px 5px;
    font-weight: 500;
    position: absolute;
    min-height: 20px;
    background-color: #e0e0e0;
    color: black;
    min-width: 40px;
    text-align: center;
    justify-content: center;
    border-radius: 8px;
}