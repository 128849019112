.menu-item-link {
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    /* height: 44px; */
    color: rgba(33, 33, 33, .8);
}

.menu-item-icon {
    margin-right: 15px;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
}

.menu-item-text {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: rgba(33, 33, 33, .8);
    text-transform: capitalize;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}