.profile-name {
    font-size: 16px;
    display: flex;
    align-items: center;
    font-weight: 600;
}

.button-menu-mobile {
    position: absolute;

}