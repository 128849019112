.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/* @import url('https://cdn.syncfusion.com/ej2/material.css'); */

.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}

.nav-item,
.navbar {
  z-index: 10000;
}

@media screen and (max-width:800px) {
  .sidebar {
    z-index: 10000000;
  }
}

.e-dlg-center-center,
.e-quick-popup-wrapper.e-device {
  z-index: 1000000 !important;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

/* color-picker style  */

#preview {
  background: transparent url('https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png') no-repeat;
  display: inline-block;
  height: 80px;
  margin: 10px 0;
  min-width: 300px;
  max-width: 600px;
  background-color: #008000;
}

.e-input-group:not(.e-float-icon-left),
.e-input-group.e-success:not(.e-float-icon-left),
.e-input-group.e-warning:not(.e-float-icon-left),
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
  border: none;
}



/* Layout */
@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: url('./assets/fonts/roboto-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: url('./assets/fonts/roboto-medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: bold;
  src: url('./assets/fonts/roboto-bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  src: url('./assets/fonts/roboto-italic.ttf') format('truetype');
}

/* ==== */

@font-face {
  font-family: "Inter";
  font-weight: 400;
  src: url("./assets/fonts/inter-regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 500;
  src: url("./assets/fonts/inter-medium.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: bold;
  src: url("./assets/fonts/inter-bold.ttf") format("truetype");
}

/* ==== */

* {
  margin: 0;
  padding: 0;
}

html {
  /* font-size: 62.5%; */
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  text-transform: initial;
  letter-spacing: initial;
  color: #212121;
  line-height: 18px;
  font-size: 14px;
  background: #fff;
  -webkit-overflow-scrolling: touch;
}

body::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

body::-webkit-scrollbar-track {
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color: #909090;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

a {
  color: #0279C7;
}

a:hover,
a:focus,
a:active {
  outline: none;
  text-decoration: none;
}

a:hover {
  color: #0279C7;
}

p {
  margin: 0;
  white-space: normal;
}

b,
strong {
  font-weight: bold;
}

label {
  font-weight: 500;
  display: block;
}

img {
  max-width: 100%;
}

ul {
  margin: 0;
}

li {
  list-style-type: none;
}

.list-style-disc {
  list-style-type: disc;
}

.tf-wrapper {
  margin: 0 40px;
}

@media(max-width:991px) {
  .ui-main {
    padding-left: 0;
  }

  .ui-header .ui-header--logo,
  .ui-header .ui-header--profile {
    display: none;
  }
}
.ant-btn-primary{
  background-color: #1677ff !important;
}
:where(.css-dev-only-do-not-override-djtmh8).ant-input{
  line-height: 2em !important;
}
:where(.css-dev-only-do-not-override-djtmh8).ant-input-number .ant-input-number-input{
  line-height: 2em !important;
}
.filter-grid__item .ant-input{
  line-height: 1.5em !important;
}
:where(.css-dev-only-do-not-override-djtmh8).ant-input-search .ant-input-search-button{
  height: 38px;
}